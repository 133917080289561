import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useStore } from "../stores/store";
import { LogDeviceParam } from "../models/LogDeviceParam";

interface Props{
    id:number;
    uniq:number;
    mdb:number;
    title:string;
}

export default observer(function ModbusConnetor({id,uniq,mdb,title}:Props){
    let { plantStore } = useStore();
    const [buttonText, setButtonText] = useState("")
    const [Loading,setLoading] = useState(false)
    const [Veriant,SetVeriant] = useState("default")
    const readParam = (e:any)=>{
        setLoading(true)
        plantStore.ReadParameter(id,uniq,mdb).then((a:LogDeviceParam[]|undefined)=>{
          setLoading(false)
          if(a){
            SetVeriant("success")
            const value:LogDeviceParam =a[0];
              setButtonText(`The result from plant:${value.plantID} m:${value.modBusRegister} q:${value.modBusUqIdentifier}  value:${value.logVal}` )
          }else{
            SetVeriant("danger")
          }
        })
    }
    return (
        <>

<Button 
className="mt-1"
size='sm' 
onClick={readParam} 
variant={Veriant}
>
{Loading?
    <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
    />:null
}
{title}

{buttonText}</Button>
        </>
    )
})
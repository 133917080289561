import { faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { Breadcrumb } from 'react-bootstrap'
import {history} from '../..'
import { Link } from 'react-router-dom'

interface Props{
    id:string;
    name?:string
}


export  default observer(function BreadCrumbs({name,id}:Props){

    return (
        <>
            <Breadcrumb style={{ marginLeft: '15px' }}>
                            <Breadcrumb.Item  href="/plants" style={{fontWeight:700}}>
                                Plants
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            <FontAwesomeIcon icon={faGear} style={{cursor:"pointer"}} className='hover' onClick={()=>{history.push("/plant/edit/"+ id)}} />
                                &nbsp;&nbsp;
                                <Link to={"/plants/"+ id}>
                                ({id}) {name}  
                                </Link>
                                </Breadcrumb.Item>
                        </Breadcrumb>
        </>
    )
})
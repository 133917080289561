import { useEffect } from 'react';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Container } from 'react-bootstrap';
import NavBar from './app/components/NavBar';
import PlantsTable from './app/components/PlantsTable';

import { observer } from 'mobx-react-lite';
import HomePage from './app/Home/HomePage';

import { Route } from "react-router-dom";
import { useStore } from './app/stores/store';
import { history } from '.';
import PlantDetails from './app/components/PlantDetails';
import PlantsCustom from './app/components/PlantsCustom';
import PlantEdit from './app/components/PlantEdit';




function App() {
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {


      
      userStore.getUser()
      .then(()=>{
        commonStore.setAppLoaded()
        //history.push('/plants')
      })
      .catch(()=>{
        commonStore.clearToken();
        history.push('/')
      })
      .finally(() =>{
          
        })


    } else {
      history.push('/')
    }

  })



  return (

    <>
      <Route exact path="/" component={HomePage} />
      <Route path={'/(.+)'}
        render={() => (
          <>
          
            <NavBar />
            <Container>
              
              <Route exact path="/Plants" component={PlantsTable} />
              <Route exact path="/Plants/:id" component={PlantDetails} />
              <Route exact path="/FPlant" component={PlantsCustom} />
              <Route exact path="/Plant/Edit/:id" component={PlantEdit}/>
            </Container>
          </>

        )}
      />

    </>

  );

}

export default observer(App);

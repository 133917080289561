
import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import PlantsStore from "./PlantsStore";
import PlantStore from "./PlantStore";

import UserStore from "./userStore";

interface Store {
    plantsStore:PlantsStore;
    plantStore:PlantStore;
    userStore:UserStore;
    commonStore:CommonStore;

}

export const store : Store = {
    plantsStore: new PlantsStore(),
    plantStore: new PlantStore(),
    userStore: new UserStore(),
    commonStore: new CommonStore()
}

export const StoreContext = createContext(store);


export function useStore(){

    return useContext(StoreContext)
}
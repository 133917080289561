import react from 'react'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { useStore } from '../stores/store';
import { useField } from 'formik';
import { User } from '../models/user';




export default function NavBar(){

  const {  userStore } = useStore();
  const LogOut = ()=>{userStore.logout()};

    return (
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="/" >RePi</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/plants">Plants</Nav.Link>
                <Nav.Link href="/fplant">Custom Plant</Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Nav>
            <NavDropdown title={localStorage.getItem("userName")} id="collasible-nav-dropdown">
              {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item> */}
              <NavDropdown.Item onClick={LogOut}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          </Container>
        </Navbar>
      );
}
import { observer } from 'mobx-react-lite';
import react, { useEffect, useState } from 'react';
import { Card, InputGroup,Form, Table  } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { history } from '../..';
import LoadingComponents from '../LoadingComponents';
import { Plant } from '../models/Plant';
import { useStore } from '../stores/store';
import PlantStore from '../stores/PlantStore';


const columns = [
    {
        name: 'מספר',
        selector: (row:any) => row.plantId,
        sortable: true,
    },
    {
        name: 'סניף',
        selector: (row:any) => row.plantName,
        sortable: true,
        cell:(row :Plant)=>{   
            return (<span>{row.plantName}</span>)
        }
    }
    

];



const handleRowClicked = (row:any) => history.push(`plants/${row.target.parentElement.dataset.plant}`)


export default observer( function PlantsTable()
{

    const {plantsStore} = useStore();
    const handleChangeSearch  = (e:any)=>{
       plantsStore.filterPlants(e.target.value)
    }
    

    useEffect(()=>{
            plantsStore.loadPlants() 
    },[plantsStore])


    if(plantsStore.loadingInitial) return <LoadingComponents inverted={true} content={'Loading Plants'}></LoadingComponents>
    
    return (
        <>
        <InputGroup className="mt-3">
        
        <Form.Control
          placeholder="Search Plant Here"
          onChange={handleChangeSearch}
        />
      </InputGroup>


        <Card className='mt-4 '>
            <Card.Body className='p-0 '>
            
                    <Table striped="columns" hover >
                        <thead>
                            <tr>
                                <th> Number</th>
                                <th> Name</th>
                                <th></th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                plantsStore.SearchPlants.map((listValue, index) => {
                                    return (
                                        <tr key={index} data-plant={listValue.plantId} onClick={handleRowClicked} style={{cursor:'pointer'}}>
                                            <td>{listValue.plantId}</td>
                                            <td>{listValue.plantName}</td>
                                            <td>{listValue.gateWayVersion}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
{/* 
                <DataTable
                    title="Plants"
                    columns={columns}
                    data={plantsStore.SearchPlants}
                    onRowClicked={handleRowClicked}
                    striped
                    subHeader
                /> */}
            </Card.Body>
            </Card>
            </>
    )
})
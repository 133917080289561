import axios, { AxiosResponse } from 'axios'
import { request } from 'http';
import { LogDeviceParam } from '../models/LogDeviceParam';
import { Plant } from '../models/Plant';
import { PlantSetting } from '../models/PlantSetting';
import { Tempreture, Tempretures } from '../models/Tempreture';
import { User, UserFormValues } from '../models/user';
import { store } from '../stores/store';
import { EventLog } from '../models/EventLog';

// const token = window.localStorage.getItem('jwt')


axios.defaults.baseURL = 'https://control.skyimu.co.il/api'  //https://localhost:44371/api' //'https://control.skyimu.co.il/api'
axios.interceptors.request.use(config => {
    const token = store.commonStore.token;


    if(token) config.headers!.Authorization = `Bearer ${token}`
    return config;
})

const responseBody =<T> (response:AxiosResponse<T>) => response.data;

const requests = {
    get:<T> (url : string ) => axios.get<T>(url).then(responseBody),
    post:<T> (url : string ,body:{}) => axios.post<T> (url,body).then(responseBody),
    Expost:<T> (url : string ,body:{},headers:{}) => axios.post<T> (url,body,headers).then(responseBody),
}

const Plants = {
    List:()=> requests.get<Plant[]>('/plant'),
    
}

const plant = {
    Single:(id:number)=> requests.get<PlantSetting>(`/plant/${id}`),
    isConnected: (id:number) => requests.get<string>(`/plant/getLocaltime/${id}`),
    isMainAppRunning: (id:number) => requests.get<string>(`/plant/checkMainApplication/${id}`),
    isApiRunnung: (id:number) => requests.get<number>(`/plant/CheckApi/${id}`),
    stopMainApp: (id: number) => requests.get<string>(`/plant/StopMainApplication/${id}`),
    startMainApp: (id: number) => requests.get<string>(`/plant/StartMainApplication/${id}`),
    StartApi: (id:number) => requests.get<number>(`/plant/StartApi/${id}`),
    Calibretion: (id:number) => requests.get<LogDeviceParam[]>(`/plant/calibration/${id}`),
    readParam: (id:number,uniqid:number,modbus:number) => requests.get<LogDeviceParam[]>(`/plant/readParam/${id}/${uniqid}/${modbus}`),
    SmartTemp: (id:number) => requests.get<Tempretures>(`/plant/readSmartTemp/${id}`),
    readConfigFile: (id:number) => requests.get<string>(`/plant/readConfigurationFile/${id}`),
    readCron: (id:number) => requests.get<string>(`/plant/readCronFile/${id}`),
    UpdateConfigFile: (id:number,config:string) => requests.post<string>(`/plant/updateConfigurationFile/${id}`,{config:config}),
    UpdateCronFile: (id:number,config:string) => requests.post<string>(`/plant/updateCronFile/${id}`,{config:config}),
    CRUDPlant:(id:number,Custplant:PlantSetting) => requests.post<string>(`/plant/CRUDPlant/${id}`,Custplant),
    Logs:(id:number)=>requests.get<EventLog[]>(`/plant/Logs/${id}`),
    Log:(id:number,log:EventLog)=>requests.post<EventLog>(`/plant/Log/${id}`,log),
    RebootPi:(id:number)=>requests.get<number>(`/plant/RequestReboot/${id}`),
    UpGrade:(id:number,file:any)=> requests.Expost<string>(`/plant/UpLoad/${id}`,{"file":file},{headers: {
        "Content-Type": "multipart/form-data",
      }}),
    InstallFirmware:(id:number)=>requests.post<EventLog>(`/plant/InstallFirmware/${id}`,{}),
    }

const  Account ={
    currnt: ()=> requests.get<User>("/Auth"),
    login: (user:UserFormValues) => {
        user.username = user.email;
        return requests.post<User>('/Auth',user)
    }
}

const agent ={
    Plants,
    plant,
    Account

}

export default agent
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Badge, Breadcrumb, Button, ButtonGroup, Card, CardGroup, Col, Container, Form, ListGroup, Row, Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import LoadingComponents from '../LoadingComponents'
import { Tempreture } from '../models/Tempreture'
import { useStore } from '../stores/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { history } from '../..'
import BreadCrumbs from './BreadCrumbs'
const columns = [
    {
        name: 'Id',
        selector: (row:any) => row.clientDeviceId,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row:any) => row.paramName,
        sortable: true,
    },
    {
        name: 'Value',
        selector: (row:any) => row.logVal,
        sortable: true,
        
    },
    {
        name: 'Register',
        selector: (row:any) => row.modBusRegister,
        sortable: true,
        
    },
    {
        name: 'UniqId',
        selector: (row:any) => row.modBusUqIdentifier,
        sortable: true,
        
    }
    

];
const Smcolumns = [
    {
        name: 'Id',
        selector: (row:Tempreture) => row.id,
        sortable: true,
    },
    {
        name: 'Loaged',
        selector: (row:Tempreture) => row.logDate,
        sortable: true,
        cell:(row:Tempreture)=>{
 
            return (moment(row.logDate).fromNow())
        }
    },
    {
        name: 'Value',
        selector: (row:Tempreture) => row.logVal,
        sortable: true,
        
    }
];


export default observer(function PlantDetails() {
    const { id } = useParams<{ id: string }>()
    const { plantStore,userStore } = useStore();
    const [cronFile, SetCronFile] = useState("");
  
    const hendleSubmit  = (values:any) =>{
 
        return false;
    }
    const [configFile, SetConfigFile] = useState("");
    const retestConnection = () => plantStore.ConnectionStatus(parseInt(id))
    const MainAppStatus = () => plantStore.MainAppStatus(parseInt(id))
    const ApiStatus = () => plantStore.ApiStatus(parseInt(id))
    const ToggleApp = () => plantStore.toggleMainApp(parseInt(id))
    const ToggleApi = () => plantStore.ToggleApi(parseInt(id))
    const Calibretion = () =>  plantStore.getCalibretionData(parseInt(id))
    const readConfig = () => {
        plantStore.AuthorizedUser()
        if(plantStore.AuthorizedHighAction){
            plantStore.readConfigurationFile(parseInt(id))
        }
        
        }
    const SmartTemps = ()=> plantStore.getSmartTempData(parseInt(id))
    const handleConfigUpdate = ()=> plantStore.UpdateConfigurationFile(parseInt(id),configFile)
    const readCron = () => {
        plantStore.AuthorizedUser()
        if(plantStore.AuthorizedHighAction){
            plantStore.readCronFile(parseInt(id)).then(()=>SetCronFile(plantStore.CronFile))
        }
     
    }
    const handleCronUpdate = ()=> plantStore.UpdateCronFile(parseInt(id),cronFile)
    useEffect(() => {
        const f =async ()=>{
            if (id) {
                    plantStore.reset();
                    plantStore.singlePlant(parseInt(id))
                    await plantStore.ConnectionStatus(parseInt(id))
                    if(plantStore.isSiampleConnectionLoading){
                        plantStore.MainAppStatus(parseInt(id))
                        plantStore.ApiStatus(parseInt(id))
                    }
                
            }
        }
        f()
    }, [id, plantStore])

    useEffect(() => {
        SetConfigFile(plantStore.configFile)
    }, [plantStore.configFile])
  

    if (plantStore.loadingInitial) return <LoadingComponents inverted={true} content={'Loading Plant Number ' + id}></LoadingComponents>

    return (
        <>

   
        <ToastContainer />
            <Container className='mt-2'>
                <Row>
                    <Col xs={12} md={6} >

                    <BreadCrumbs name={plantStore.Plant?.plantName} id={id}/>
            
                       
                    </Col>
          
                    <Col xs={12}  md={6} className='mb-3'>
                          

                        <ButtonGroup style={{ marginRight: "10px" }}>
                            <Button
                                size='sm'
                                variant={plantStore.isApiConnected ? "success" : "danger"}
                                onClick={ApiStatus}
                            >
                               Api
                            </Button>
       
                        </ButtonGroup>
                        <ButtonGroup>
                    
                            <Button
                                size='sm'
                                variant={plantStore.isApplicationRunning ? "success" : "danger"}
                                onClick={MainAppStatus}
                            >
                                {plantStore.isApplicationRunning ? `Core Running! Handle ${plantStore.isAppiicationRunningText}` : plantStore.isAppiicationRunningText}
                            </Button>
                            <Button
                                size='sm'
                                variant={plantStore.isApplicationRunning ? "danger" : "success"}
                                onClick={ToggleApp}
                            >
                                {plantStore.loadingMain ?

                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : ""}
                                {"  "}
                                {plantStore.isApplicationRunning ? `Stop` : `Start`}
                            </Button>


                        </ButtonGroup>
                     
                    </Col>
                    
                </Row>
            </Container>
            <Container>
                <CardGroup>
                    <Card>
                        <Card.Body>
                            {/* <Card.Title>Plant {id}: Informations</Card.Title> */}

                            <ListGroup variant="flush">
                                <ListGroup.Item><b>Service Port: </b>{plantStore.Plant?.port}</ListGroup.Item>

                                <ListGroup.Item><b>Service Api Port: </b>{plantStore.Plant?.wanIP}</ListGroup.Item>
               
                            </ListGroup>

                        </Card.Body>
                        <Card.Footer>
                            <Button
                                size='sm'
                                variant={plantStore.isSiampleConnectionLoading ? 'success' : 'danger'}
                                disabled={!plantStore.isSiampleConnectionLoading}
                                onClick={retestConnection}
                            >
                                {plantStore.isSiampleConnectionLoading ? 'Connected' : plantStore.SimapleConnctionText}
                            </Button>
                            {"  "}

                            <small className="text-muted">{plantStore.isSiampleConnectionLoading ? plantStore.isSiampleConnection : ""}</small>
                        </Card.Footer>
                    </Card>
                    <Card>

                        <Card.Body>

                                <Button 
                                    variant='light'
                                    
                                    size='sm' 
                                    onClick={Calibretion}>
                                        Modbus Read
                                        {"  "}
                                        {plantStore.calibretionLoading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : ""}
                                           
                                </Button>

                                <Button 
                                    variant='light'
                                    
                                    size='sm' 
                                    onClick={SmartTemps}>
                                        SmartTemp Read
                                        {"  "}
                                        {plantStore.SmartLoading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : ""}
                                           
                                </Button>
                                <Button 
                                    variant='light'
                                    size='sm' 
                                    onClick={readConfig}>
                                        Configuration Read
                                        {"  "}
                                        {plantStore.configurationLoading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : ""}
                                           
                                </Button>
                                <Button
                                variant='light'
                                size='sm'
                                onClick={readCron}>
                                Cron Read
                                {"  "}
                                {plantStore.CronLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : ""}
                            </Button>

                           {plantStore.calibretiondata && plantStore.calibretiondata.length >0?
                           
                           <DataTable
                           pagination 
                           dense
                           className='mt-1'
                           columns={columns}
                           data={plantStore.calibretiondata}/>
                            :
                            null
                        }
                        {plantStore.SmartData && plantStore.SmartData.length >0?
                           
                           <DataTable
                           pagination 
            
                           className='mt-1'
                           columns={Smcolumns}
                           data={plantStore.SmartData}/>
                            :
                            null
                        }
                        {configFile?
                        <Form className='mt-1'>
                            <Form.Control
                            as="textarea"
                            style={{ height: '630px' }}
                            value = {configFile}
                            onChange={e => SetConfigFile(e.target.value)}
                            
                            />
                            <Button className='mt-1' onClick={handleConfigUpdate} variant='warning' size='sm'  type="button">Publish To Pi</Button>
                        </Form>
                        :
                      null    
                        }
                        {plantStore.CronFile?
                        <Form className='mt-1'>
                            <Form.Control
                            as="textarea"
                            style={{ height: '630px' }}
                            value = {cronFile}
                            onChange={e => SetCronFile(e.target.value)}
                            />
                            <Button  className='mt-1' onClick={handleCronUpdate} variant='warning' size='sm'  type="button">Publish To Pi</Button>
                        </Form>
                        :
                      null    
                        }


                        </Card.Body>
                   
                    </Card>
                </CardGroup>
            </Container>
        </>
    )
})
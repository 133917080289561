import { faGear,faCircleDot,faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { observer } from "mobx-react-lite";
import { Breadcrumb, Button, ButtonGroup, Card, CardGroup, Col, Container, Form, FormControl, ListGroup, Row, Spinner } from "react-bootstrap";
import { useStore } from "../stores/store";
import BreadCrumbs from "./BreadCrumbs";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import DataTable from "react-data-table-component";
import { ChangeEvent, useEffect, useState } from "react";
import { time } from "console";
import PlantStore from "../stores/PlantStore";
import PlantsStore from "../stores/PlantsStore";
import { EventLog } from "../models/EventLog";
import LogViewer from "./LogViewer";
import LoadingComponents from "../LoadingComponents";
import { ToastContainer } from "react-toastify";
import ModbusConnector from "./ModbusConnector";


const Logstablecolumns = [
    {
        name: 'Id',
        selector: (row:any) => row.id,
        sortable: true,
    },
    {
        name: 'date',
        selector: (row:any) => row.date,
        sortable: true,
    
    },
    {
        name: 'Value',
        selector: (row:any) => row.value,
        sortable: true,
        
    }
];


export default observer(function PlantEdit() {

    let { plantStore } = useStore();
    let { id } = useParams<{ id: any }>()
    const [file, setFile] = useState<File>();
    const [initialValues, SetinitialValues] = useState({ customerId: -1, gateWayVersion: 'RTU', id: -1, lanIP: '10.0.0.201', plantName: '', port: 0, wanIP: '' });
    const GetLogs = ()=>{plantStore.GetLogs(id)}
    const Reboot = ()=>{
        plantStore.AuthorizedUser();
        if(plantStore.AuthorizedHighAction){
            plantStore.RebootPi(id)
        }
        plantStore.AuthorizedHighAction = false;
    }
    const FetchLog = (a:EventLog,getFull:boolean)=>{plantStore.GetLog(a,id,getFull)}
    const CloseLogView = ()=>{plantStore.log=null}
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
        }
      };
    const handleUploadClick = () => {plantStore.Upgrade(id,file)}
    const installfireware = ()=>{plantStore.InstallFirmWare(id)}
    const handelVersion = ()=>{
        plantStore.AuthorizedUser();
        if(plantStore.AuthorizedHighAction){
            plantStore.toggleVersion?plantStore.toggleVersion=false:plantStore.toggleVersion=true
        }
        plantStore.AuthorizedHighAction = false;
    }

   
    useEffect(()=>{
        plantStore.reset()
        const f =async ()=>{
            if(id){
                plantStore.singlePlant(id)
                    .then(()=>SetinitialValues(plantStore.Plant!))
      
                await plantStore.ConnectionStatus(id)
                if(plantStore.isSiampleConnectionLoading){
                    plantStore.MainAppStatus(parseInt(id))
                    plantStore.ApiStatus(parseInt(id))
                }
            }
        }
        f();
    },[])

 
 
    if (plantStore.loadingInitial) return <LoadingComponents inverted={true} content={'Loading Plant Number ' + id}></LoadingComponents>

    return (
        <>
<ToastContainer />
            <Container className='mt-2'>
                <Row>
                    <Col xs={12} md={6} >
                        <BreadCrumbs name={plantStore.Plant?.plantName} id={id} />
                     
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-between mb-2">
                  
                        <Button  size='sm'
                                variant={"light"}
                                 onClick={GetLogs}
                                >Load Logs</Button>
                      <ButtonGroup>
                        <Button  size='sm'
                                variant={"warning"}
                                onClick={ handelVersion}
                                >  {!plantStore.isSiampleConnectionLoading ?   
                                // <Spinner
                                //     as="span"
                                //     animation="border"
                                //     size="sm"
                                //     role="status"
                                //     aria-hidden="true"/>
                                null
                                    :null} Version</Button>
                      

                        <Button  size='sm'
                                variant={"danger"}
                                 onClick={Reboot}
                                >  {!plantStore.isSiampleConnectionLoading ?   <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />:null} Reboot</Button>
                             

                             </ButtonGroup>
                          
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row >
                    <Col xs={12} md={6}>
                    <CardGroup>
                        <Card>
                        <Card.Header className="d-flex justify-content-between ">
                            
                            Plant {id}: Edit Informations    
                                                <div>
                                                
                                                <FontAwesomeIcon icon={faCircle} color={plantStore.isApiConnected?"green":"red"} /> Api 
                                                    <span>  </span>
                                                <FontAwesomeIcon icon={faCircle} color={plantStore.isApplicationRunning?"green":"red"} /> App
                                                </div>
                                            </Card.Header>
                        <Card.Body>
                            <Formik

                                initialValues={initialValues}
                                enableReinitialize={true}
                                onSubmit={(values, { setSubmitting }) => {
                                    plantStore.CRUDPlant(values)
                                
                                    .finally(()=>setSubmitting(false))
                                                     
                                }
                            }
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                
                        
                                    isSubmitting,

                                }) => (
                                    <form
                                        className='ui form' onSubmit={handleSubmit} autoComplete='off'>

                                   
                                            
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col xs="auto">
                                                            <b>Port: </b>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <FormControl
                                                                size='sm'
                                                                type='Number'
                                                                name='port'
                                                                id='port'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.port}
                                                            />
                                                        </Col>

                                                    </Row>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                <Row>
                                                        <Col xs="auto">
                                                            <b>Customer: </b>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <FormControl
                                                                size='sm'
                                                                type='Number'
                                                                name='customerId'
                                                                id='customerId'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.customerId}
                                                            />
                                                        </Col>

                                                    </Row>
                                                </ListGroup.Item>
                                                <ListGroup.Item><Row>
                                                        <Col xs="auto">
                                                            <b>Lan Ip: </b>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <FormControl
                                                                size='sm'
                                                                type='text'
                                                                name='lanIP'
                                                                id='lanIP'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.lanIP}
                                                            />
                                                        </Col>
                                                    </Row></ListGroup.Item>
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col xs="auto">
                                                            <b>World IP: </b>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <FormControl
                                                                size='sm'
                                                                type='text'
                                                                name='wanIP'
                                                                id='wanIP'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.wanIP}
                                                            />
                                                        </Col>

                                                    </Row>

                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                <Row>
                                                        <Col xs="auto">
                                                            <b>Name: </b>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <FormControl
                                                                size='sm'
                                                                type='text'
                                                                name='plantName'
                                                                id='plantName'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.plantName}
                                                            />
                                                        </Col>

                                                    </Row>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        


                                    
                                            <Button size='sm' type="submit">Update
                                                {"  "}
                                                {isSubmitting ?
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> : ""}
                                            </Button>
                                    </form>

                                )}
                            </Formik>

                            </Card.Body>
                          
                        </Card>

                    </CardGroup>
                    </Col>
                    <Col xs={12} md={6}>
                    {plantStore.toggleVersion ? 
                        <Card>
                            <Card.Header>Upload Version</Card.Header>
                            <Card.Body >    
                     
                                    <Form.Group controlId="formFile" className="mb-3 form-inline">

                                        <Form.Label>Firmware Update *.zip <span className="text-muted"> {file && `${file.name} - ${file.size /1024 /1024 ? `${(file.size /1024 /1024).toFixed(2)}MB`:null}`}</span>

                                        </Form.Label>
                                        <Row className="align-items-center">
                                        <Col xs="auto">
                                            <Form.Control type="file"  onChange={handleFileChange}/>
                                        </Col>

                                        {plantStore.versionIsLoaded?
                                        <Col xs="auto">
                                            <Button onClick={ installfireware} variant={"warning"} >Install Firmware</Button>
                                        </Col>
                                        :<Col xs="auto">
                                            <Button onClick={handleUploadClick} variant={"light"} >Upload
                                            {plantStore.isUploading ?

                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> : ""}
                                                {"  "}
                               
                                            </Button>
                                        </Col>}
                                        
                                        </Row>
                                    </Form.Group>
                                         
                           </Card.Body>
                           </Card>


                        :null }
                            



                        {plantStore.logs && plantStore.logs.length>0 ? 
                        <Card className="mt-1">
                            <Card.Body className="p-0">                        
                            <DataTable
                            pagination 
                            dense
                            defaultSortFieldId='1'
                            defaultSortAsc={false}
                            // onRowClicked={(e)=>FetchLog(e,true)}
                            // onRowDoubleClicked={(e)=>FetchLog(e,true)}
                            columns={[
                                {
                                    name: 'Id',
                                    selector: (row:any) => row.id,
                                    sortable: true,
                                    omit:true
                                },
                                {
                                    name: 'date',
                                    selector: (row:any) => row.date,
                                    sortable: true,
                                
                                },
                                {
                                    name: 'Value',
                                    selector: (row:any) => row.value,
                                    sortable: true,
                                    
                                },
                                {
                                    name: 'Action',
                                    cell: (row, index, column) => { 
                                        return (
                                            <ButtonGroup>
                                                <Button size="sm" variant={"info"} className="btn-small"  onClick={()=>{FetchLog(row,false)}}>Latest Log</Button>
                                                <Button size="sm" variant={"light"} className="btn-small" onClick={()=>{FetchLog(row,true)}}>Full Log</Button>
                                            </ButtonGroup>
                                        )}
                                    
                                    
                                },
                                
                            ]}
                           data={plantStore.logs}/>
                           </Card.Body>
                           </Card>


                        :null }

                    <Card  className="mt-1">
                        <ModbusConnector id={id} mdb={256} uniq={1} title="dixel 1"  ></ModbusConnector>
                        <ModbusConnector id={id} mdb={256} uniq={2} title="dixel 2"  ></ModbusConnector>
                        <ModbusConnector id={id} mdb={256} uniq={3} title="dixel 3" ></ModbusConnector>
                        <ModbusConnector id={id} mdb={0} uniq={11} title="pilot 1"  ></ModbusConnector>
                        <ModbusConnector id={id} mdb={0} uniq={12} title="pilot 2"  ></ModbusConnector>
                    </Card>

                        </Col> 
                </Row>
            </Container>
{plantStore.log?
            <Container className="mt-1">
                <Row>
                    <Col xs={12}> 
                    <LogViewer eventLog={plantStore.log}  id={id}  CloseLogView={CloseLogView}/>
                    </Col>
                </Row>
            </Container>
:null}
        </>
    )

})
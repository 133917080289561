import { makeAutoObservable, reaction } from "mobx";


export default class CommonStore{
    token: string | null = window.localStorage.getItem("jwt");
    appLoaded = false;

    constructor(){
        makeAutoObservable(this)

        reaction(
            ()=> this.token,
            token =>{
                if(token){
                    window.localStorage.setItem('jwt',token)
                }else{
                    window.localStorage.removeItem('jwt')
                }
            }
        )
    }

    setToken = (token: string | null)=>{
        this.token = token;
    }
    clearToken = ()=>{
        this.token = null;
        window.localStorage.removeItem('jwt')
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }
}
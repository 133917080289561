import { observer } from "mobx-react-lite";
import { EventLog } from "../models/EventLog";
import { useParams } from "react-router-dom";
import { Card, CloseButton } from "react-bootstrap";


interface Props{
    eventLog:EventLog | null,
    id:number,
    CloseLogView():void;
}

export default observer (function LogViewer({CloseLogView,id,eventLog}:Props){

    return (
        <>
        <Card>
            <Card.Header className="d-flex justify-content-between">
                Log Plants {id} 
            <CloseButton onClick={CloseLogView} />
            </Card.Header>
            <Card.Body style={{height:"40vh",overflowY:"auto"}}>
            <Card.Subtitle className="mb-2 text-muted">Log #{eventLog?.id}  {eventLog?.value}</Card.Subtitle>
     
            <Card.Text>
        


                    <code style={{whiteSpace: "pre-line"}}>  {eventLog?.content}</code>

         
</Card.Text>
            </Card.Body>
            </Card>
        </>
    )
})

import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { Plant } from "../models/Plant";

export default class PlantsStore{
    plants : Plant[] = [];
    SearchPlants : Plant[] = [];

    editMode = false;
    loading = false;
    loadingInitial = false;




    constructor(){
        makeAutoObservable(this)
    }

    loadPlants = async () =>{

        this.loadingInitial = true;
        try {
            const plants = await agent.Plants.List()
           
            this.plants = plants;
            this.SearchPlants = plants;
            this.loadingInitial = false;
        } catch (error) {
            console.log(error)
            this.loadingInitial = false;

        }
        
    }

    filterPlants = (searchvalue:string)=>{
     
            if(searchvalue.length != 0){
                this.SearchPlants = this.plants;

                this.SearchPlants =  this.SearchPlants.filter(function (el) {
                    return  (el.plantId.toString().includes(searchvalue)) || el.plantName.trim().includes(searchvalue) 
                  });
       
                    
            }else{
                this.SearchPlants = this.plants;
            }

    }
   

}



import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { PlantSetting } from "../models/PlantSetting";
import moment from 'moment';
import { LogDeviceParam } from "../models/LogDeviceParam";
import { Tempreture } from "../models/Tempreture";
import { toast } from "react-toastify";
import { EventLog } from "../models/EventLog";

export default class PlantStore{
 
    AuthorizedHighAction = false;
    logs:EventLog[] | null= null;
    log:EventLog | null = null;

    configurationLoading = false;
    configFile:string =""; 
    
    CronLoading = false;
    CronFile:string =""; 

    calibretionLoading = false;
    calibretiondata: LogDeviceParam[] = [];
    
    SmartLoading = false;
    SmartData:Tempreture[] = [];

    Plant : PlantSetting | null = null;
    
    editMode = false;
    loadingInitial = false;
    
    isApiConnected = false
    isApiConnectedText = "Connecting...";
    loadingApi = false;
    
    loadingMain = false;
    isApplicationRunning = false;
    isAppiicationRunningText = "Connecting...";
    
    SimapleConnctionText = "Connecting..."
    isSiampleConnection = "";
    isSiampleConnectionLoading = false;
    
    loadingCustom = false;
    versionIsLoaded = false;
    isUploading = false;
    toggleVersion = false;
    constructor(){
        makeAutoObservable(this)
        this.reset();
    }
    reset() {
        this.versionIsLoaded = false;
        this.isUploading = false;
        this.toggleVersion = false;
        this.logs = [];
        this.log = null;
        this.configurationLoading = false;
        this.configFile =""; 
    
        this.CronLoading = false;
        this.CronFile=""; 

        this.calibretionLoading = false;
        this.calibretiondata = [];
    
        this.SmartLoading = false;
        this.SmartData= [];

        this.Plant  = null;
    
        this.editMode = false;
        this.loadingInitial = false;
    
        this.isApiConnected = false
        this.isApiConnectedText = "Connecting...";
        this.loadingApi = false;
    
        this.loadingMain = false;
        this.isApplicationRunning = false;
        this.isAppiicationRunningText = "Connecting...";
    
        this.SimapleConnctionText = "Connecting..."
        this.isSiampleConnection = "";
        this.isSiampleConnectionLoading = false;
    
        this.loadingCustom = false;
        this.AuthorizedHighAction = false;
    }
    AuthorizedUser = async()=>{
        try{
            if(!this.AuthorizedHighAction){
                var user = await agent.Account.currnt()
                
                if(user.username.indexOf('sky')+1){
                    this.AuthorizedHighAction = true 
                }
            }

        }catch(error){

        }
        
        if(this.AuthorizedHighAction) return true;
        if(window.prompt("Protected action,use your brain") == "brain")
            this.AuthorizedHighAction = true
    }



    CrearWorkingArea = ()=>{
        runInAction(()=>{
            this.calibretiondata = []
            this.SmartData = []
            this.configFile = "";
           
        })
    }
   

    CRUDPlant = async (values:PlantSetting)=> {
        this.CrearWorkingArea()
        try {
            const plant = await agent.plant.CRUDPlant(values.id,values)
        } catch (error) {
            toast.error("No Connection to Pi");
            return;
        }
    }
   
    getCalibretionData = async (id:number) =>{

        await this.ConnectOrCheckApi(id)
        this.CrearWorkingArea()

        this.calibretionLoading = true;
        try {
            const LogDeviceParam = await agent.plant.Calibretion(id);
      
            if(LogDeviceParam.length ===0){
                toast.warning("Empty payload");
            }
            runInAction(()=>{
                this.calibretiondata = LogDeviceParam;
                this.calibretionLoading = false;
            })
            
        } catch (error) {
            runInAction(()=>{
                this.calibretionLoading = false;
                toast.error("Error try again! or restrat Core ");
            })
        }
    }

    ReadParameter = async (id:number,uniqid:number,modbus:number) =>{

        await this.ConnectOrCheckApi(id)
        this.CrearWorkingArea()
        this.calibretionLoading = true;
        try {
            const LogDeviceParam = await agent.plant.readParam(id,uniqid,modbus);
            
        
            if(LogDeviceParam.length ===0){
                toast.warning("Empty payload");
            }
            runInAction(()=>{
                this.calibretiondata = LogDeviceParam;
                this.calibretionLoading = false;
            })
            return LogDeviceParam;
            
        } catch (error) {
            runInAction(()=>{
                this.calibretionLoading = false;
                toast.error("Error try again! or restrat Core ");
                return null;
            })
        }
    }


    readConfigurationFile = async (id:number) =>{
        this.CrearWorkingArea()
        this.configurationLoading = true;
        this.CronFile = "";
        if(!this.isSiampleConnection){
            toast.error("No Connection to Pi");
            this.configurationLoading = false;
            return;
        }
        await this.ConnectOrCheckApi(id)

        try {
            const configuration = await agent.plant.readConfigFile(id);

            runInAction(()=>{
                this.configFile = configuration;
                this.configurationLoading = false;
            })
        } catch (error) {
            this.configurationLoading = false;
        }
    }

    readCronFile = async (id:number) =>{
        this.CrearWorkingArea()
        this.configFile = "";
        this.CronLoading = true;
        if(!this.isSiampleConnection){
            toast.error("No Connection to Pi");
            this.CronLoading = false;
            return;
        }
        await this.ConnectOrCheckApi(id)

        try {
            const configuration = await agent.plant.readCron(id);

            runInAction(()=>{
                this.CronFile = configuration;
                this.CronLoading = false;
            })
        } catch (error) {
            this.CronLoading = false;
        }
    }

    UpdateConfigurationFile = async (id:number,config:string) =>{
        runInAction(()=>{
            this.configurationLoading = true;
        })
        
        if(!this.isSiampleConnection){
            toast.error("No Connection to Pi");
            this.configurationLoading = false;
            return;
        }
        await this.ConnectOrCheckApi(id)
   
        try {
            const configuration = await agent.plant.UpdateConfigFile(id,config);
       
            runInAction(()=>{
                this.configurationLoading = false;
                this.configFile = "";
            })
        } catch (error) {
            this.configurationLoading = false;
            toast.error("Cant Update File");
        }
    }

    UpdateCronFile = async (id:number,config:string) =>{
        runInAction(()=>{
            this.CronLoading = true;
        })
        if(!this.isSiampleConnection){
            toast.error("No Connection to Pi");
            this.CronLoading = false;
            return;
        }
        await this.ConnectOrCheckApi(id)
   
        try {
            const configuration = await agent.plant.UpdateCronFile(id,config);
       
            runInAction(()=>{
                this.CronLoading = false;
                this.CronFile = "";
            })
        } catch (error) {
            this.CronLoading = false;
            toast.error("Cant Update File");
        }
    }


    getSmartTempData = async (id:number) =>{
        this.CrearWorkingArea()
        if(!this.isSiampleConnection){
            toast.error("No Connection to Pi");
            return;
            }
        await this.ConnectOrCheckApi(id)
        runInAction(()=>{
       
     
            this.configFile = ""
            this.SmartLoading = true;
            this.calibretiondata = [];
        })
        try {
            const {tempreture} = await agent.plant.SmartTemp(id);
            runInAction(()=>{
                this.SmartData = tempreture;
                this.SmartLoading = false;
            })
            
        } catch (error) {
            runInAction(()=>{
                this.SmartLoading = false;
            })
        }
    }

    singlePlant = async (id: number)=>{
        runInAction(()=>{
            this.loadingInitial = true;})
        try {
            const plant = await agent.plant.Single(id)
   
            runInAction(()=>{
            this.Plant = plant;
            this.loadingInitial = false;})
        } catch (error) {
            runInAction(()=>{
            this.loadingInitial = false;})

        }
    

    }


    ConnectionStatus = async (id:number)=>{
        runInAction(() => {
            this.SimapleConnctionText = "Connecting...";
            this.isSiampleConnection = ""
            this.isSiampleConnectionLoading = false;
        })
        try {
            const connection = await agent.plant.isConnected(id)
          


            this.isSiampleConnection ='Handshake '+ connection
            runInAction(() => {
             
                this.isSiampleConnectionLoading = true;
            })
             

        } catch (error) {
            runInAction(()=>{
            this.SimapleConnctionText = "No Connection";
            this.isSiampleConnectionLoading = false;})
        }
    }

    ApiStatus = async (id:number) =>{
        runInAction(()=>{
            this.isApiConnected = false
            this.isApiConnectedText = "Connecting...";
        })

        try {
            const apistatus = await agent.plant.isApiRunnung(id);
            runInAction(()=>{
            if(apistatus===0){
                this.isApiConnected = false
                this.isApiConnectedText = "Disconnected";
            }
            else{
                this.isApiConnected = true
                this.isApiConnectedText = "Connected";
            }})
  
        } catch (error) {
            
        }
    }

    ToggleApi = async (id:number) =>{
        runInAction(()=>{
            this.loadingApi = true;
        })

        try {
            const apistatus = await agent.plant.StartApi(id);
            runInAction(()=>{
            if(apistatus===0){
                this.isApiConnected = false
                this.isApiConnectedText = "Disconnected";
            }
            else{
                this.isApiConnected = true
                this.isApiConnectedText = "Connected";
            }
            this.loadingApi = false;
        })
        } catch (error) {
            
        }
    }

    MainAppStatus = async (id:number) =>{
        runInAction(()=>{
            this.isApplicationRunning = false;
            this.isAppiicationRunningText = "";
            this.isAppiicationRunningText = "Testing..";
                
        })
        try {
            if(this.isSiampleConnectionLoading){
                const running =await agent.plant.isMainAppRunning(id)
        
                runInAction(()=>{
                    this.isAppiicationRunningText = running;
                    this.isApplicationRunning = true;
                })   
            }else{
                runInAction(()=>{
                    this.isAppiicationRunningText = "No Communication";
                }) 
            }
        } catch (error) {
            runInAction(()=>{
                this.isAppiicationRunningText = "Application Not Responding";
            }) 
        }
    }

    toggleMainApp = async (id:number) =>{
        this.loadingMain = true;
        try {
            if(this.isApplicationRunning){
                const running =await agent.plant.stopMainApp(id)
             
            }else{
                const running =await agent.plant.startMainApp(id)
            }
            
            await this.MainAppStatus(id)
        
            this.loadingMain = false;

            
            
        } catch (error) {
            this.loadingMain = false;
        }

    }

    ConnectOrCheckApi = async (id:number) => {
        if(!this.isSiampleConnection){
            toast.error("No Connection to Pi");
            return;
        }
        try {
            if(!this.isApiConnected){
                await this.ToggleApi(id)
            }
        } catch (error) {
            toast.error("cant connect to Api");
        }
    }

    BasicConnection =  () => {
        if(!this.isSiampleConnection){
            toast.error("No Connection to Pi");
            return;
        }
       
    }

    GetLogs = async(id:number)=>{



        this.loadingInitial = true
      await this.ConnectOrCheckApi(id).then(()=>this.CrearWorkingArea())
      .finally(async ()=>{
        try{
            const logs:EventLog[] = await agent.plant.Logs(id)
            runInAction(()=>{
                
                this.logs = logs

                if(logs.length == 0)
                    toast.info("There are no Logs Fo the device");
                })
                this.loadingInitial = false
            }
            catch(error){
                toast.error("Cant get Logs, For Source");
                this.loadingInitial = false
            } 
      })
}
    GetLog = async(data:EventLog,id:number,getFull:boolean)=>{
        this.loadingInitial = true
        this.log = null;
        await this.ConnectOrCheckApi(id).then(()=>this.CrearWorkingArea())
        .finally(async ()=>{
            try{
                data.full = getFull;
             const log:EventLog = await agent.plant.Log(id,data);
             this.log = log;
             this.loadingInitial = false
            }
            catch(error){
                this.loadingInitial = false
            }

        });

    }
    RebootPi = async(id:number)=>{
        this.isSiampleConnectionLoading= false;
       
        console.log("enter")
        await this.ConnectOrCheckApi(id).then(()=>this.CrearWorkingArea()).
        finally(async ()=>{
            await agent.plant.RebootPi(id);
            this.isApiConnected = false;
            this.isApplicationRunning = false;
            await this.ConnectOrCheckApi(id).then(()=>this.CrearWorkingArea())
        var b =   setInterval(async ()=>{
            console.log("start")
            await this.ConnectionStatus(id)
            if(this.isSiampleConnectionLoading){
                console.log("end")
                this.MainAppStatus(id)
                this.ApiStatus(id)
                clearInterval(b)
                
            }
          },10000);  
        })
    }
    Upgrade = async(id:number,file:any)=>{
        this.isUploading = true;
        await this.ConnectOrCheckApi(id).then(()=>this.CrearWorkingArea())
        .finally(async()=>{
        if (!file) {
            toast.error("no file selected!")
            return;
        }
        try{

            var result =  await agent.plant.UpGrade(id,file);
            toast.success("Upload is done.")
            this.versionIsLoaded = true;
            this.isUploading = false;
        
        }catch(error){
            toast.error("Error! Faild uploading")
            this.versionIsLoaded = false;
            this.isUploading = false;
        }})

    }
    InstallFirmWare  = async(id:number)=>{
        await this.ConnectOrCheckApi(id).then(()=>this.CrearWorkingArea())
        .finally(async ()=>{
            try{

                var result =  await agent.plant.InstallFirmware(id);
                toast.success("Done.")
                await this.MainAppStatus(id)
                this.versionIsLoaded = false;
            
            }catch(error){
                toast.error("Error! Faild installing")
                this.versionIsLoaded = false;
            }
        })
    }
}



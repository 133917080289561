import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Breadcrumb, Button, ButtonGroup, Card, CardGroup, Col, Container, Form, FormControl, ListGroup, Row, Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import LoadingComponents from '../LoadingComponents'
import { Tempreture } from '../models/Tempreture'
import { store, useStore } from '../stores/store'
import * as Yup from 'yup';
import { Formik } from 'formik'
import { PlantSetting } from "../models/PlantSetting";

const columns = [
    {
        name: 'Id',
        selector: (row:any) => row.clientDeviceId,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row:any) => row.paramName,
        sortable: true,
    },
    {
        name: 'Value',
        selector: (row:any) => row.logVal,
        sortable: true,
        
    },
    {
        name: 'Register',
        selector: (row:any) => row.modBusRegister,
        sortable: true,
        
    },
    {
        name: 'UniqId',
        selector: (row:any) => row.modBusUqIdentifier,
        sortable: true,
        
    }
    

];
const Smcolumns = [
    {
        name: 'Id',
        selector: (row:Tempreture) => row.id,
        sortable: true,
    },
    {
        name: 'Loaged',
        selector: (row:Tempreture) => row.logDate,
        sortable: true,
        cell:(row:Tempreture)=>{
 
            return (moment(row.logDate).fromNow())
        }
    },
    {
        name: 'Value',
        selector: (row:Tempreture) => row.logVal,
        sortable: true,
        
    }

    

];


const validationSchema = Yup.object({
    email:Yup.string().required("this is requierd")
})


export default observer(function PlantCastom() {

    const { plantStore } = useStore();
    const [configFile, SetConfigFile] = useState("");
    const [cronFile, SetCronFile] = useState("");
    const [id, SetId] = useState(-1);
    const [initialValues, SetinitialValues] = useState({customerId:3,gateWayVersion:'RTU',id:-1,lanIP:'10.0.0.201',plantName:'CustomPlant',port:20012,wanIP:'http://10.0.0.201:30012'});
    
    useEffect(() => {
     
        if (id) {
           plantStore.singlePlant(id).then(()=>SetinitialValues(plantStore.Plant!))
        }
        }
    , [])
 

    const retestConnection = () => plantStore.ConnectionStatus(id)
    const MainAppStatus = () => plantStore.MainAppStatus(id)
    const ApiStatus = () => plantStore.ApiStatus(id)
    const ToggleApp = () => plantStore.toggleMainApp(id)
    const ToggleApi = () => plantStore.ToggleApi(id)
    const Calibretion = () =>  plantStore.getCalibretionData(id)
    const SmartTemps = ()=> plantStore.getSmartTempData(id)
    const readConfig = () =>{ 
        plantStore.AuthorizedUser()
        if(plantStore.AuthorizedHighAction){
        plantStore.readConfigurationFile(id).then(()=>SetConfigFile(plantStore.configFile))}
        plantStore.AuthorizedHighAction = false;
    }
    const readCron = () => {
        plantStore.AuthorizedUser()
        if(plantStore.AuthorizedHighAction){
            plantStore.readCronFile(id).then(()=>SetCronFile(plantStore.CronFile))
        }
        plantStore.AuthorizedHighAction = false;
    }
    const handleConfigUpdate = ()=> plantStore.UpdateConfigurationFile(id,configFile)
    const handleCronUpdate = ()=> plantStore.UpdateCronFile(id,cronFile)
    


    if (plantStore.loadingInitial) return <LoadingComponents inverted={true} content={'Loading Plant Number ' + id}></LoadingComponents>

    return (
        <>
   
        <ToastContainer />
            <Container className='mt-4'>
                <Row>
                    <Col>
                        <Breadcrumb style={{ marginLeft: '15px' }}>
                            <Breadcrumb.Item href="/plants">
                                Plants
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{id}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>

                    <Col >
                        <ButtonGroup style={{ marginRight: "10px" }}>
                            <Button

                                size='sm'
                                variant={plantStore.isApiConnected ? "success" : "danger"}
                                onClick={ApiStatus}
                            >
                                {plantStore.isApiConnected ? `Api ${plantStore.isApiConnectedText}` : `Api ${plantStore.isApiConnectedText}`}
                            </Button>
                            <Button
                                size='sm'
                                variant={plantStore.isApiConnected ? "success" : "success"}
                                onClick={ToggleApi}
                                disabled={plantStore.isApiConnected ? true : false}
                            >
                                {plantStore.loadingApi ?

                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : ""}
                                {"  "}
                                {plantStore.isApiConnected ? `` : `Start`}
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button
                                size='sm'
                                variant={plantStore.isApplicationRunning ? "success" : "danger"}
                                onClick={MainAppStatus}
                            >
                                {plantStore.isApplicationRunning ? `Core Running! Handle ${plantStore.isAppiicationRunningText}` : plantStore.isAppiicationRunningText}
                            </Button>
                            <Button
                                size='sm'
                                variant={plantStore.isApplicationRunning ? "danger" : "success"}
                                onClick={ToggleApp}
                            >
                                {plantStore.loadingMain ?

                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : ""}
                                {"  "}
                                {plantStore.isApplicationRunning ? `Stop` : `Start`}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>
            <Container>
                <CardGroup>
                    <Card>
                        <Card.Body>
                            <Card.Title>Plant {id}: Informations</Card.Title>
                            <Formik 
                            
                            initialValues={initialValues}
                            onSubmit={(values, { setSubmitting }) => {
                                plantStore.CRUDPlant(values)
                                .then(retestConnection)
                                .then(MainAppStatus)
                                .then(ApiStatus)
                                .finally(()=>setSubmitting(false))
                                                 
                            }}
                            >
                            {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
              
                        }) =>(
                                <form
                                    className='ui form' onSubmit={handleSubmit}   autoComplete='off'>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>
                                        <Row>
                                            <Col xs="auto">
                                                <b>Port: </b>
                                            </Col>
                                            <Col xs="auto">
                                                <FormControl 
                                                    size='sm'
                                                    type='Number' 
                                                    name='port' 
                                                    id='port' 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.port}
                                                />
                                            </Col>
                                
                                        </Row> 
                                        </ListGroup.Item>
                                        <ListGroup.Item><b>Customer: </b> Free </ListGroup.Item>
                                        <ListGroup.Item><b>Local IP: </b>10.0.0.201</ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col xs="auto">
                                                    <b>World IP: </b>
                                                </Col>
                                                <Col xs="auto">
                                                    <FormControl
                                                    size='sm' 
                                                    type='text' 
                                                    name='wanIP' 
                                                    id='wanIP' 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.wanIP}
                                                    />
                                                </Col>
                                             
                                            </Row>
                                                            
                                        </ListGroup.Item>
                                        <ListGroup.Item><b>Name: </b>Custom</ListGroup.Item>
                                    </ListGroup>
                                    <Button size='sm' type="submit">Try Connecting...

                                    {"  "}
                                    {isSubmitting?
                                                                                <Spinner
                                                                                    as="span"
                                                                                    animation="border"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                /> : ""}
                                    </Button>
                                </form>
                                        
                                        )}
                            </Formik>
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                size='sm'
                                variant={plantStore.isSiampleConnectionLoading ? 'success' : 'danger'}
                                disabled={!plantStore.isSiampleConnectionLoading}
                                onClick={retestConnection}
                                >
                                {plantStore.isSiampleConnectionLoading ? 'Connection Established' : plantStore.SimapleConnctionText}
                            </Button>
                            {"  "}
                            <small className="text-muted">{plantStore.isSiampleConnectionLoading ? plantStore.isSiampleConnection : ""}</small>
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title>Plant Interface</Card.Title>
                                <Button 
                                    variant='light'
                                    size='sm' 
                                    onClick={Calibretion}>
                                        Modbus Read
                                        {"  "}
                                        {plantStore.calibretionLoading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : ""}
                                           
                                        </Button>

                            <Button
                                variant='light'

                                size='sm'
                                onClick={SmartTemps}>
                                SmartTemp Read
                                {"  "}
                                {plantStore.SmartLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : ""}

                            </Button>
                            <Button
                                variant='light'
                                size='sm'
                                onClick={readConfig}>
                                Configuration Read
                                {"  "}
                                {plantStore.configurationLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : ""}
                            </Button>
                            <Button
                                variant='light'
                                size='sm'
                                onClick={readCron}>
                                Cron Read
                                {"  "}
                                {plantStore.CronLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : ""}
                            </Button>
                           {plantStore.calibretiondata && plantStore.calibretiondata.length >0?
                           
                           <DataTable
                           pagination 
                           dense
                           className='mt-1'
                           columns={columns}
                           data={plantStore.calibretiondata}/>
                            :
                            null
                        }
                        {plantStore.SmartData && plantStore.SmartData.length >0?
                           
                           <DataTable
                           pagination 
            
                           className='mt-1'
                           columns={Smcolumns}
                           data={plantStore.SmartData}/>
                            :
                            null
                        }
                        {plantStore.configFile?
                        <Form className='mt-1'>
                            <Form.Control
                            as="textarea"
                            style={{ height: '630px' }}
                            value = {configFile}
                            onChange={e => SetConfigFile(e.target.value)}
                            />
                            <Button  className='mt-1' onClick={handleConfigUpdate} variant='warning' size='sm'  type="button">Publish To Pi</Button>
                        </Form>
                        :
                      null    
                        }
                        {plantStore.CronFile?
                        <Form className='mt-1'>
                            <Form.Control
                            as="textarea"
                            style={{ height: '630px' }}
                            value = {cronFile}
                            onChange={e => SetCronFile(e.target.value)}
                            />
                            <Button  className='mt-1' onClick={handleCronUpdate} variant='warning' size='sm'  type="button">Publish To Pi</Button>
                        </Form>
                        :
                      null    
                        }
                        </Card.Body>
                   
                    </Card>
                </CardGroup>
            </Container>
        </>
    )
})
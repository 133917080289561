import react from 'react'
import { Spinner } from 'react-bootstrap';


interface Porps{
    inverted?:boolean;
    content:string;
}

export default function LoadingComponents({inverted = true,content =  'Loading...'} :Porps){
    return (
        <div id='overlay'>
            <div className="text-center spinner align-middle py-5">
                <Spinner animation="border" /><br/>
                {content}
            </div>
        </div>
        )
}
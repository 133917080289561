import { Form, Formik } from 'formik'
import react from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import MyPasswordInput from '../../common/MyPasswordInput'
import MyTextInput from '../../common/MyTextInput'
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite'

import agent from '../api/agent'
import { useStore } from '../stores/store'

const validationSchema = Yup.object({
    email:Yup.string().required("this is requierd")
})


export default observer(function LoginForm(){
    const {userStore} = useStore();

    return (

   
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
          
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 ">
                  <h2 className="fw-bold mb-2 text-uppercase ">Re-Pi</h2>
                  <p className=" mb-2">Please enter your login and password!</p>
                  <div className="mb-3">
                  <Formik 
                        validationSchema={validationSchema}
                        initialValues={{email:'',password:''}}
                        onSubmit={values => userStore.login(values)}
                        >
                        {({handleSubmit,isSubmitting}) =>(
                            <Form
                            className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <MyTextInput name='email' placeholder='Email Address' label='Email Address'></MyTextInput>
                                <MyPasswordInput name='password' placeholder='Password' label='Password'></MyPasswordInput>
                                <Button type="submit">Login</Button>
                            </Form>
                        )}
                    </Formik>

                    <div className="mt-3">
                      
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
  

    )
})
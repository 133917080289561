
import react from 'react'
import { useField } from 'formik';
import { Form } from 'react-bootstrap';

interface Props{
    placeholder:string;
    name:string;
    label?:string;
    description?:string
}


export default function MyTextInput(props:Props){
    const [filed,meta] = useField(props.name);
    return (
        <Form.Group  className="mb-3" controlId="formBasicEmail">
            <Form.Label>{props.label}</Form.Label>
            <Form.Control  isInvalid={meta.touched && !!meta.error} {...filed} {...props} />
            { meta.touched && !!meta.error ? (
                <Form.Text className="text-danger">
                    {meta.error}
                </Form.Text>
                ) :null}
            
      </Form.Group>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Router} from "react-router-dom";
import { store, StoreContext } from './app/stores/store';
import { createBrowserHistory } from "history";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const history = createBrowserHistory();
root.render(
  
<StoreContext.Provider value={store}>
    <Router  history={history}>
      <App />
    </Router >
</StoreContext.Provider>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

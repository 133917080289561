import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { history } from "../..";
import { useStore } from "../stores/store";
import LoginForm from "../users/LoginForm";


export default observer(function HomePage(){
        const {userStore} = useStore() 
       
            if(userStore.isLoggedIn )
            {
                history.push('/plants')
            }



const runthejuls = ()=>{}

    return (
    <>
        <Container>
        {userStore.isLoggedIn ?
            <Button  href="/plants">Go To Plants</Button>
        :
            <LoginForm/>
        }
        </Container>
    </>

)

})